import React, { useEffect } from "react"

import Layout from "../@yumgmbh/gatsby-theme-yum-components/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { changeLanguage } from "i18next"

import aeFlag from "../images/country-flags/ae.svg"
import atFlag from "../images/country-flags/at.svg"
import auFlag from "../images/country-flags/au.svg"
import beFlag from "../images/country-flags/be.svg"
import bgFlag from "../images/country-flags/bg.svg"
import brFlag from "../images/country-flags/br.svg"
import chFlag from "../images/country-flags/ch.svg"
import cnFlag from "../images/country-flags/cn.svg"
import czFlag from "../images/country-flags/cz.svg"
import deFlag from "../images/country-flags/de.svg"
import dnFlag from "../images/country-flags/dn.svg"
import esFlag from "../images/country-flags/es.svg"
import fiFlag from "../images/country-flags/fi.svg"
import frFlag from "../images/country-flags/fr.svg"
import huFlag from "../images/country-flags/hu.svg"
import icFlag from "../images/country-flags/ic.svg"
import inFlag from "../images/country-flags/in.svg"
import itFlag from "../images/country-flags/it.svg"
import nlFlag from "../images/country-flags/nl.svg"
import noFlag from "../images/country-flags/no.svg"
import plFlag from "../images/country-flags/pl.svg"
import ptFlag from "../images/country-flags/pt.svg"
import rsFlag from "../images/country-flags/rs.svg"
import ruFlag from "../images/country-flags/ru.svg"
import roFlag from "../images/country-flags/ro.svg"
import skFlag from "../images/country-flags/sk.svg"
import swFlag from "../images/country-flags/sw.svg"
import trFlag from "../images/country-flags/tr.svg"
import gbFlag from "../images/country-flags/gb.svg"
import usFlag from "../images/country-flags/us.svg"
import zaFlag from "../images/country-flags/za.svg"

const globalWebsitesMapping =
  require("../../config/global-websites").globalWebsitesMapping

const flagMapping = {
  ae: aeFlag,
  at: atFlag,
  au: auFlag,
  be: beFlag,
  bg: bgFlag,
  br: brFlag,
  ch: chFlag,
  cn: cnFlag,
  cz: czFlag,
  de: deFlag,
  dn: dnFlag,
  es: esFlag,
  fi: fiFlag,
  fr: frFlag,
  hu: huFlag,
  ic: icFlag,
  in: inFlag,
  it: itFlag,
  nl: nlFlag,
  no: noFlag,
  pl: plFlag,
  pt: ptFlag,
  rs: rsFlag,
  ru: ruFlag,
  ro: roFlag,
  sk: skFlag,
  sw: swFlag,
  tr: trFlag,
  gb: gbFlag,
  us: usFlag,
  za: zaFlag,
}

const WebsitesPage = () => {
  const language = "en"
  // set i18n-language
  // set klaro-language
  useEffect(() => {
    changeLanguage(language)
    if (typeof window !== "undefined") {
      if (window.klaro && window.klaro.render && window.klaroConfig) {
        //window.klaro.defaultConfig.lang = language

        setTimeout(() => {
          window.klaro.render({
            ...window.klaroConfig,
            ...{ lang: language },
          })
          if (window.klaro.getManager) {
            let manager = window.klaro.getManager()
            manager.applyConsents()
          }
        }, 50)
      }
    }
  }, [language])

  return (
    <>
      <Layout>
        {/* +++++++ Special Hero +++++++ */}
        <BlockWrapper
          block={{}}
          blockPadding=""
          blockWidth="screen"
          showHeadline="false"
        >
          <div className="relative mx-auto overflow-hidden bg-black md:h-80">
            <Image
              image={{
                filename:
                  "https://a.storyblok.com/f/149938/1800x663/1b6d11c6a0/rothenberger.jpg",
              }}
              title="ROTHENBERGER CAS Produkt"
              alt="ROTHENBERGER CAS Produkt"
              className="object-cover w-full h-auto md:h-full"
              adaptToPixelRatio={true}
            />
            <div className="bg-gray-800 md:bg-transparent md:inset-0 md:absolute md:bg-gradient-to-r from-black-2/3-transparent to-transparent">
              <div className="h-full px-4 py-8 mx-auto max-w-screen-2xl">
                <div className="flex flex-col justify-between h-full md:w-1/2 lg:w-1/3">
                  <div>
                    <Headline
                      className="text-white"
                      headlineLevel="h1"
                      headline="Welcome to ROTHENBERGER"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockWrapper>
        {/* +++++++ Laenderwebseiten +++++++ */}
        <section>
          <div className="py-block block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              {/* +++++++ Western Europe 1 +++++++ */}
              <div className="w-full px-grid md:w-1/2 lg:w-1/4">
                <span className="block px-2 pb-3 font-bold min-h-[3.75rem]">
                  {globalWebsitesMapping[0].region}:
                </span>
                {globalWebsitesMapping[0].websites.map(
                  (website, index) =>
                    index < globalWebsitesMapping[0].websites.length / 2 && (
                      <div key={website.title}>
                        <Link
                          className="block px-2 pb-3 hover:text-primary min-h-[3.75rem]"
                          link={website.path ? website.path : website.url}
                        >
                          <img
                            src={flagMapping[website.countryCode]}
                            alt={website.title}
                            className="inline-block  mb-0.5 mr-2 align-middle"
                          />{" "}
                          {website.title}
                        </Link>
                      </div>
                    )
                )}
              </div>
              {/* +++++++ Western Europe 2 +++++++ */}
              <div className="w-full px-grid md:w-1/2 lg:w-1/4 md:order-3 lg:order-2">
                <span className="block md:h-[3.75rem]"></span>
                {globalWebsitesMapping[0].websites.map(
                  (website, index) =>
                    index >= globalWebsitesMapping[0].websites.length / 2 && (
                      <div key={website.title}>
                        <Link
                          className="block px-2 pb-3 hover:text-primary min-h-[3.75rem]"
                          link={website.path ? website.path : website.url}
                        >
                          <img
                            src={flagMapping[website.countryCode]}
                            alt={website.title}
                            className="inline-block  mb-0.5 mr-2 align-middle"
                          />{" "}
                          {website.title}
                        </Link>
                      </div>
                    )
                )}
              </div>
              {/* +++++++ Eastern Europe +++++++ */}
              <div className="w-full px-grid md:w-1/2 lg:w-1/4 md:order-2 lg:order-3">
                <span className="block px-2 pb-3 font-bold min-h-[3.75rem]">
                  {globalWebsitesMapping[1].region}:
                </span>
                {globalWebsitesMapping[1].websites.map((website, index) => (
                  <div key={website.title}>
                    <Link
                      className="block px-2 pb-3 hover:text-primary min-h-[3.75rem]"
                      link={website.path ? website.path : website.url}
                    >
                      <img
                        src={flagMapping[website.countryCode]}
                        alt={website.title}
                        className="inline-block  mb-0.5 mr-2 align-middle"
                      />{" "}
                      {website.title}
                    </Link>
                  </div>
                ))}
              </div>
              {/* +++++++ Rest of the world +++++++ */}
              <div className="w-full px-grid md:w-1/2 lg:w-1/4 md:order-last">
                {globalWebsitesMapping.map(
                  (region, index) =>
                    index > 1 && (
                      <>
                        <span className="block px-2 pb-3 font-bold min-h-[3.75rem]">
                          {region.region}:
                        </span>
                        {region.websites.map((website, index) => (
                          <div key={website.title}>
                            <Link
                              className="block px-2 pb-3 hover:text-primary min-h-[3.75rem]"
                              link={website.path ? website.path : website.url}
                            >
                              <img
                                src={flagMapping[website.countryCode]}
                                alt={website.title}
                                className="inline-block  mb-0.5 mr-2 align-middle"
                              />{" "}
                              {website.title}
                            </Link>
                          </div>
                        ))}
                      </>
                    )
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default WebsitesPage
